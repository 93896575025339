import React from 'react';
import { connect } from 'react-redux';
import { get, omit } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { getTreehouseEligiblePath, TreehouseModel } from 'client/data/models/treehouse';
import { FeatureSpecsPaths, VehicleModel } from 'client/data/models/vehicle-v2';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { LEAD_FORM_CONFIGS } from 'site-modules/shared/constants/lead-form/lead-form-configs';

const CORE_5769_MAP = {
  true: LEAD_FORM_CONFIGS.QUICK_QUOTES_TRIM_TARGETED_ROI_ALL,
  false: LEAD_FORM_CONFIGS.QUICK_QUOTES_TRIM_ROI_ALL,
};

function leadFormCoreWrapper(LeadFormComponent) {
  function LeadFormCoreWrapper({
    pageName,
    isCore5769FeatureEnabled,
    options,
    styleAttributes,
    isTreehouseEligible,
    ...props
  }) {
    const restProps = omit(props, ['dispatch', 'loadModel', 'setModelValue']);
    const leadFormOptions = {
      ...options,
      hasEmailTreehousePromotion: !!(
        isTreehouseEligible &&
        (styleAttributes?.electric || styleAttributes?.pluginElectric)
      ),
    };

    const leadFormCtrl = <LeadFormComponent {...restProps} options={leadFormOptions} />;

    if (pageName === 'new_model_core_connect') {
      return (
        <LeadFormComponent
          {...restProps}
          options={{
            ...leadFormOptions,
            leadFormComponentConfig: CORE_5769_MAP[isCore5769FeatureEnabled],
          }}
        />
      );
    }

    if (pageName === 'new_model_core') {
      return (
        <Experiment name="core-5189-roi-sort" showDefault>
          <Recipe name="ctrl" isDefault>
            <LeadFormComponent
              {...restProps}
              options={{
                ...leadFormOptions,
                leadFormLayoutOptions: { ...leadFormOptions.leadFormLayoutOptions, isCore6313Test: true },
              }}
            />
          </Recipe>
          <Recipe name="chal">
            <LeadFormComponent
              {...restProps}
              options={{
                ...leadFormOptions,
                leadFormComponentConfig: LEAD_FORM_CONFIGS.QUICK_QUOTES_TRIM_ROI,
                leadFormLayoutOptions: { ...leadFormOptions.leadFormLayoutOptions, isCore6313Test: true },
              }}
            />
          </Recipe>
        </Experiment>
      );
    }

    return leadFormCtrl;
  }

  LeadFormCoreWrapper.displayName = `WithLeadFormCoreWrapper(${LeadFormComponent.name})`;
  return LeadFormCoreWrapper;
}

const mapStateToProps = state => ({
  pageName: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
  isCore5769FeatureEnabled: !!get(state, 'featureFlags["core-5769-targeted-roi"]'),
});

export const withLeadFormCoreWrapper = LeadFormComponent =>
  connect(mapStateToProps)(
    connectToModel(leadFormCoreWrapper(LeadFormComponent), {
      styleAttributes: bindToPath(
        ({ params: { styleId } }) => FeatureSpecsPaths.buildStyleAttributesPath(styleId),
        VehicleModel
      ),
      isTreehouseEligible: bindToPath(getTreehouseEligiblePath, TreehouseModel),
    })
  );
